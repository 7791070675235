import React, { useContext } from "react";
import { QuestionSection, RadioRange } from "../../components/utility/quote";
import { navigate } from "gatsby";

import SolutionsContext from "../../contexts/solutionsContext";
import RadioCircle from "../../svgs/radioCircle";

const Next = "/solutions/Questions/3";
const Points = [
  {
    question: "How many users do you have?",
    progress: "70%",
    options: [
      {
        answer: "1-4",
        to: Next,
        icon: <RadioCircle />,
      },
      { answer: "10-24", to: Next, icon: <RadioCircle /> },
      { answer: "11-25", to: Next, icon: <RadioCircle /> },
      { answer: "25-49", to: Next, icon: <RadioCircle /> },
      { answer: "50+", to: Next, icon: <RadioCircle /> },
    ],
  },
];

export default function Question2({ title }) {
  const { que2, setQue2 } = useContext(SolutionsContext);

  return Points.map((item) => {
    return (
      <QuestionSection
        title={title}
        question={item.question}
        progress={item.progress}
      >
        {item.options.map((items) => {
          return (
            <RadioRange
              key={items.answer}
              icon={items.icon}
              value={items.answer}
              title={items.answer}
              isSelected={que2 === items.answer}
              onChange={(e) => {
                setQue2(e.target.value);
                navigate(items.to);
              }}
            />
          );
        })}
      </QuestionSection>
    );
  });
}
